<template>
  <div class="about">
<!--    <div class="swiper-box">-->
<!--      <div class="swiper-box" style="z-index: 999">-->
<!--        <img :src="require('../../assets/about/appdown.jpg')" alt="" />-->
<!--      </div>-->
<!--    </div>-->
    <div class="about-cen">
      <div class="center" v-show="tabShou === 0">
        <div style="display: flex;flex-direction: row;justify-content: center;"><img class="title-img" src="../../assets/about/title0.jpg" style="width:270px;height:110px" /></div>
        <div>
          <div>
            <img src="../../assets/about/jzhjs.jpg"  alt=""/>
          </div>
          <div class="description" style="line-height: 35px;margin-top:20px;">
            <p style="font-size:20px;">应用描述</p>
            <p style="text-indent: 2em;font-size:16px;">标题：【探索未来，轻松生活——佳智惠，您的全能助手】</p>
            <p style="text-indent: 2em;font-size:16px;">想象一下，一款集智能搜索、便捷操作与高效生活于一身的应用程序正在等待您的下载。这就是我们的【佳智惠】，一款专为现代人打造的安卓神器，让科技融入日常，让生活更加精彩。

              首先，只需轻点【安装】按钮或者扫描下方的二维码，您就可以在智能手机上快速、免费下载【佳智惠】的apk版本。无论您是安卓设备爱好者，还是寻找实用工具的新手，这款应用都能无缝适应，确保您的体验流畅无阻。

              一旦安装完毕，打开应用程序，您将被其直观的界面和丰富的功能所吸引。【佳智惠】的强大搜索引擎，能帮您迅速找到所需的信息，无论是日常生活问题，还是专业咨询，都能轻松找到答案。比如，一键搜索美食食谱，或是查询最新的科技资讯，一切触手可及。

              此外，我们特别注重用户体验，【佳智惠】还提供了个性化推荐服务，根据您的使用习惯，智能推送适合的内容，节省您的时间，让生活更加有序。而且，对于安卓游戏和安卓应用的爱好者，这里更是天堂，您可以直接从应用内发现并下载热门游戏和工具，无需离开这个一站式平台。

              别再犹豫了，点击【安装】，让【佳智惠】成为您生活中不可或缺的好伙伴。现在就行动起来，开启这场科技与智慧的探索之旅吧！让我们一起，佳智惠，让生活更简单！</p>
          </div>
          <div style="display: flex;flex-direction: row;justify-content: center;">
            <div disabled="flex;flex-direction: column;justify-content: center;">
              <img  src="../../assets/about/jzh-app-ewm.jpg" style="width:150px;height:150px" />
              <div style="width:150px;font-weight: 500;font-size:20px;display: flex;flex-direction: row;justify-content: center;">扫码立即下载</div>
            </div>
          </div>

        </div>
      </div>
      <div class="center"
           v-show="tabShou == 1">
        <div class="title-img"
             :style="{ backgroundImage: 'url(' + titleImg + ')' }"></div>
        <!-- <div v-for="(item,index) in culture" :key="index">
            <img class="qualification" :src="item.img" alt="">
          </div> -->
        <div class="culture-box">
          <div v-for="(item, index) in culture"
               :key="index">
            <p class="description"
               v-html=" item.detailses?JSON.parse(item.detailses)[langtype]:'' "></p>
          </div>
        </div>
      </div>
      <div class="center"
           v-show="tabShou == 2">
        <div class="title-img"
             :style="{ backgroundImage: 'url(' + titleImg + ')' }"></div>
        <div class="qualification-box">
          <div class="qualification"
               v-for="(item, index) in qualification.items"
               :key="index">
            <!-- <img class="img"
                 :src="item.img"
                 alt="" /> -->
            <el-image class="img"
                      :src="item.img"
                      :preview-src-list="srcList"></el-image>
            <p class="qname">{{ item.names?(JSON.parse(item.names)[langtype]?JSON.parse(item.names)[langtype]:item.name):item.name}}</p>
          </div>
        </div>
      </div>
      <!-- <div class="center" v-show="tabShou == 3">
        <div>
          <dl>
            <div
              class="title-img"
              :style="{ backgroundImage: 'url(' + advantage + ')' }"
            ></div>
            <dd>
              1、深圳佳智惠科技有限公司集研发、生产、销售一体，源头厂家直接供货，没有中间商赚取差价，把最大的利润空间让利给客户；
            </dd>
            <dd>
              2、公司高度重视产品的研发，已投入资金3400万，计划融资3000万用于新品开发、品牌推广、营销渠道搭建等，进一步拓展代理商、经销商、加盟商的销售渠道，增加利润来源。
            </dd>
            <dd>
              3、公司巨资引进国内外顶级的现代化生产线和检测设备，并先后通过IOS9001质量管理体系认证、中国3C认证、欧盟CE认证等18项多个国家和地区的专业认证，拥有卓越的品质、过硬的质量和完善的售后；
            </dd>
            <dd>
              4、公司研发团队由国内外知名专家学者和业内资深工程师组成，累计获得各项技术专利68项，研发生产的产品始终站在行业的前沿，精品荟萃，种类齐全。
            </dd>
            <div
              class="title-img"
              :style="{ backgroundImage: 'url(' + policy + ')' }"
            ></div>
            <dd>
              1、营销指导：对于经销商如何开拓当地市场，如何跟进具体项目，公司提供营销方法培训和具体案例的解剖分析，以及项目落地的跟踪指导，同时总部多渠道、多平台推广及曝光、品牌联合运营营销、项目导入扶持，保证在最短的时间实现盈利。
            </dd>
            <dd>
              2、现场指导：公司为合作伙伴提供现场指导支持，在项目谈判、落地等关键节点供现场指导服务，共同促使项目顺利落地。
            </dd>
            <dd>
              3、技术培训：客户加盟实现先培训后加盟，行业技术在线培训，厂商技术落地扶持等相关技术指导，安全有保障。
            </dd>
            <dd>
              4、价格保护：公司制定销售价格的指导及保护机制，对于工程项目提供报备及协调管理机制，统一市场拓展行动。
            </dd>
            <dd>
              5、售后服务：公司建立了严密的服务体系和制度，以“专业、规范、团队、高效”的服务宗旨为客户提供响应及时、解决问题有效、过程规范、内容全面的售后服务。
            </dd>
            <div
              class="title-img"
              :style="{ backgroundImage: 'url(' + goal + ')' }"
            ></div>
            <dd>
              我们的目标是创民族品牌、造优质产品、造福社会，3-4年达到上市公司的标准，力争5年时间做成A股上市企业，成为智能制造的高新企业、家喻户晓的知名企业。
              未来，深圳佳智惠科技有限公司将始终如一地坚持自主研发路线、贯彻自主创新理念，秉承创造社会价值、实现自我增值的经营理念，以满足客户需求为己任、以开拓、创新、创造为宗旨，以诚信、专业、拼搏、感恩的企业价值观成就行业标杆，为我国各行业安全生产和人民幸福生活保驾护航。
            </dd>
          </dl>
        </div>
        <div
          class="title-img"
          :style="{ backgroundImage: 'url(' + investmentImg + ')' }"
        ></div>
        <div class="agent-form">
          <img src="../../assets/about/cooperation.jpg" alt="" />
          <agent-form class="agent-box"></agent-form>
        </div>
        <div
          class="title-img"
          :style="{ backgroundImage: 'url(' + titleImg + ')' }"
        ></div>
        <div class="qualification-box">
          <img
            v-for="(item, index) in partnerss"
            :key="index"
            :src="item.img"
            alt=""
          />
        </div>
      </div> -->
      <div class="center"
           v-show="tabShou == 3">
        <div class="title-img"
             :style="{ backgroundImage: 'url(' + titleImg + ')' }"></div>
        <div class="info wow fadeInUp2"
             data-wow-duration="1.5s"
             data-wow-delay="0.1s">
          <div class="container"
               v-for="(item, index) in contact.items"
               :key="index">
            <div class="about-bottom">
              <div class="about-left">
                <div class="box">
                  <div class="text t1">
                    <i class="el-icon-phone" style="margin-right:5px"></i>{{$t('about.dh')}}: {{item.names?(JSON.parse(item.names)[langtype]?JSON.parse(item.names)[langtype]:item.name):item.name}}
                  </div>
                  <div class="text t2">
                    <i class="el-icon-message"  style="margin-right:5px"></i>{{$t('about.yx')}}: {{  item.expansions?(JSON.parse(item.expansions)[langtype]?JSON.parse(item.expansions)[langtype]:item.expansion):item.expansion}}
                  </div>
                  <div class="text t3">
                    <svg t="1693988057711" class="icon" viewBox="0 0 1039 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4012" width="25" height="25"><path d="M1011.296677 412.899958 607.720062 98.818633c-58.950623-46.784536-132.378983-46.904263-191.095269-0.177032L12.81384 412.899958c-13.114692 10.160407-15.358803 30.873148-5.139044 43.809785 10.278087 12.995989 29.299303 17.10968 42.176588 7.125281l78.413907-61.024865 0 367.366925c0 121.391744 51.42523 188.743712 173.997869 188.743712l423.422615 0c122.573663 0 169.83199-69.88465 169.83199-191.334723L895.517767 402.582985l78.682014 61.252039c5.434779 4.253884 11.990079 6.323009 18.548448 6.323009 8.859785 0 17.721617-4.924149 23.629163-12.424984C1026.596128 444.797435 1024.352017 423.061388 1011.296677 412.899958zM639.705544 895.579165 383.741871 895.579165 383.741871 639.492696l255.963673 0L639.705544 895.579165zM831.761698 766.780731c0 79.670527-30.529317 128.798435-106.074897 128.798435l-22.01541 0 0-258.855536c-0.085958-2.705622-2.65548-60.789505-60.487629-60.789505L380.26263 575.934125c2.303462 0-60.488653-1.299599-60.488653 60.488653l0 259.156388-17.510816 0c-67.260893 0-110.643959-51.17452-110.643959-128.255059l-0.046049-413.782048 262.385942-204.199729c36.860513-29.241998 79.271438-29.299303 116.487038 0.176009l261.314541 203.430202L831.760674 766.780731zM703.671391 636.422777l0 0.300852C703.677531 636.916011 703.671391 636.836193 703.671391 636.422777z" p-id="4013" fill="#707070"></path></svg>
                    {{$t('about.zbgsmc')}}: {{  item.zb_name?(JSON.parse(item.zb_name)[langtype]?JSON.parse(item.zb_name)[langtype]:item.zb_name[0]):item.JSON.parse(item.zb_name[0])}}
                  </div>
                  <div class="text t4">
                    <i class="el-icon-location-information"  style="margin-right:5px"></i>{{$t('about.zbgsdz')}}:
                    {{ item.keywords?(JSON.parse(item.keywords)[langtype]?JSON.parse(item.keywords)[langtype]:item.keyword):item.keyword }}
                  </div>
                  <div class="text t5">
                    <svg t="1693988057711" class="icon" viewBox="0 0 1039 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4012" width="25" height="25"><path d="M1011.296677 412.899958 607.720062 98.818633c-58.950623-46.784536-132.378983-46.904263-191.095269-0.177032L12.81384 412.899958c-13.114692 10.160407-15.358803 30.873148-5.139044 43.809785 10.278087 12.995989 29.299303 17.10968 42.176588 7.125281l78.413907-61.024865 0 367.366925c0 121.391744 51.42523 188.743712 173.997869 188.743712l423.422615 0c122.573663 0 169.83199-69.88465 169.83199-191.334723L895.517767 402.582985l78.682014 61.252039c5.434779 4.253884 11.990079 6.323009 18.548448 6.323009 8.859785 0 17.721617-4.924149 23.629163-12.424984C1026.596128 444.797435 1024.352017 423.061388 1011.296677 412.899958zM639.705544 895.579165 383.741871 895.579165 383.741871 639.492696l255.963673 0L639.705544 895.579165zM831.761698 766.780731c0 79.670527-30.529317 128.798435-106.074897 128.798435l-22.01541 0 0-258.855536c-0.085958-2.705622-2.65548-60.789505-60.487629-60.789505L380.26263 575.934125c2.303462 0-60.488653-1.299599-60.488653 60.488653l0 259.156388-17.510816 0c-67.260893 0-110.643959-51.17452-110.643959-128.255059l-0.046049-413.782048 262.385942-204.199729c36.860513-29.241998 79.271438-29.299303 116.487038 0.176009l261.314541 203.430202L831.760674 766.780731zM703.671391 636.422777l0 0.300852C703.677531 636.916011 703.671391 636.836193 703.671391 636.422777z" p-id="4013" fill="#707070"></path></svg>
                    {{$t('about.fbgsmc')}}: {{ item.fb_name?(JSON.parse(item.fb_name)[langtype]?JSON.parse(item.fb_name)[langtype]:item.fb_name[0]):item.fb_name[0] }}
                  </div>
                  <div class="text t6">
                    <i class="el-icon-location-information"  style="margin-right:5px"></i>{{$t('about.fbgsdz')}}:
                    {{$t('about.fbgsmc')}}: {{ item.fb_address?(JSON.parse(item.fb_address)[langtype]?JSON.parse(item.fb_address)[langtype]:item.fb_address[0]):item.fb_address[0] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-map">
          <div id="allmap"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import AMapLoader from "@amap/amap-jsapi-loader";
import AgentForm from "./agentForm.vue";
export default {
  props: [],
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper;
    },
  },
  data () {
    return {
      tabList: [
        { name: "bbjj", img: require("../../assets/about/title1.jpg") },
        { name: "bbwh", img: require("../../assets/about/title2.jpg") },
        { name: "ryzz", img: require("../../assets/about/title5.png") },
        // { name: "招商代理", img: require("../../assets/about/title3.jpg") },
        { name: "lxwm", img: require("../../assets/about/title4.jpg") },
      ],
      advantage: require("../../assets/about/jm.png"),
      policy: require("../../assets/about/zc.png"),
      goal: require("../../assets/about/wm.png"),
      tabShou: 0,
      langtype: 0,
      titleImg: require("../../assets/about/title1.jpg"),
      investmentImg: require("../../assets/about/title6.png"),
      location: "佳智惠App介绍",
      culture: [],
      inbanner: [],
      introduction: [],
      qualification: [],
      contact: [],
      partnerss: [],
      map: null,
      config: require("../../components/config"),
      infoWindow: "",
      companyInfoList: {},
      srcList: [],
      qufanList:[],
      dadu:'',
      swiperOption: {
        loop: true, // 是否循环轮播
        autoplay: true, // 是否可以自动轮播
        slidesPerView: 1, // 可是区域内可展示多少个块
        spaceBetween: 30, // 块之间间隔距离
        initialSlide: 0, // 默认初始显示块
        freeMode: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperList: [
        {
          imgs:require("../../assets/about/appdown.jpg")
        }
      ]
    };
  },
  name: "videoPLay",
  components: { swiper, AgentForm, swiperSlide },
  created () {
    if (this.$route.query.tab) {
      this.tabShou = this.$route.query.tab
    }
    switch (localStorage.getItem('lang')) {
      case "zh":
        this.langtype = 0;
        break;
      case "en":
        this.langtype = 1;
        break;
      case "es":
        this.langtype = 6;
        break;
      case "ru":
        this.langtype = 2;
        break;
      case "de":
        this.langtype = 3;
        break;
      case "fr":
        this.langtype = 4;
        break;
      case "ar":
        this.langtype = 5;
        break;
    }
    this.tabList.forEach((item, index) => {
      item.name = this.$t('nav.' + item.name)
    })
    this.tabList[0].img = require("../../assets/about/title1_" + this.langtype + ".png")
    this.tabList[1].img = require("../../assets/about/title2_" + this.langtype + ".png")
    this.tabList[2].img = require("../../assets/about/title5_" + this.langtype + ".png")
    this.tabList[3].img = require("../../assets/about/title4_" + this.langtype + ".png")
    this.titleImg = require("../../assets/about/title1_" + this.langtype + ".png")
    if (this.$route.query.tab) {
      this.tabShou = this.$route.query.tab;
    }
    this.getlist();
  },
  mounted () {
    this.companyInfoList = JSON.parse(localStorage.getItem("companyInfo"));
    setTimeout(() => { }, 1000);

    const _this = this;
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      // this.$wow.init()
    });
  },
  methods: {
    handleClickSlide () {
      // console.log(222);
    },
    async getlist () {
      let inbanner = await this.api.get(this.apiPath.inbanner, {});
      this.inbanner = inbanner;
      let introduction = await this.api.get(this.apiPath.dynamicDetail + `196` + '/detail', {});
      console.log(introduction)
      this.introduction = [introduction];
      let qualification = await this.api.get(this.apiPath.qualification, {});
      this.qualification = qualification;
      console.log(this.qualification)
      qualification.items.sort((a, b) => { return b.type - a.type })
      console.log(qualification.items)

      for (var item of qualification.items) {
        console.log(item.img)
        this.srcList.push(item.img)
      }
      let culture = await this.api.get(this.apiPath.dynamicDetail + "203" + '/detail', {});
      console.log(culture)
      this.culture = [culture];
      let contact = await this.api.get(this.apiPath.contact, {});
      this.contact = contact;
      console.log(this.contact)
      let partners = await this.api.get(this.apiPath.partners, {});
      this.partnerss = partners.items;
      let res = await this.api.get(this.apiPath.aboutSwiper, {});
      this.swiperList = res;
      console.log(this.swiperList)
      if (res.items.length === 0) {
        this.swiperList.items.push({
          img: res.img
        })
      }
    },
    init () {
      // 初始化地图
      AMapLoader.load({
        key: this.config.aMapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.ToolBar",
          "AMap.Autocomplete",
          "AMap.PlaceSearch",
          "AMap.Geolocation",
          "AMap.Geocoder",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
          plugins: ["misc/PositionPicker"], // 需要加载的 AMapUI ui插件
        },
      })
        .then((AMap) => {
          let that = this;
          this.map = new AMap.Map("allmap", {
            zoom: 15,
            center: [113.856018, 22.588541], //初始化地图中心点
          });
          that.map.addControl(new AMap.ToolBar());
          that.map.addControl(new AMap.Autocomplete());
          that.map.addControl(new AMap.PlaceSearch());
          that.map.addControl(new AMap.Geocoder());

          // 实例化Autocomplete
          let autoOptions = {
            city: "全国",
          };
          that.geocoder = new AMap.Geocoder(autoOptions);
          var marker = new AMap.Marker({
            position: new AMap.LngLat(113.856018, 22.588541),
          });
          this.map.add(marker);
          var info = [];
          info.push(
            '<div style="padding:0px 0px 0px 4px;"><b>深圳佳智惠软件有限公司</b>'
          );
          info.push(`电话 : ${companyInfoList.title}`);
          info.push(`地址 : ${companyInfoList.description}</div></div>`);
          this.infoWindow = new AMap.InfoWindow({
            content: info.join("<br/>"), //使用默认信息窗体框样式，显示信息内容
          });
          this.infoWindow.open(this.map, [113.856018, 22.588541]);
        })
        .catch((e) => { });
    },
    addTab (item, index) {
      this.tabShou = index;
      this.titleImg = item.img;
      this.location = item.name;
      if (index == 3) {
        this.init();
      }
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.about-map {
  width: 100%;
  height: 500px;
  margin-top: 30px;
  #allmap {
    height: 500px;
  }
}
.about-cen {
  padding: 0px 100px;
  display: flex;
  margin-bottom: 50px;
  .tab {
    color: #000;
    width: 280px;
    background: #eee;
    padding-top: 40px;
    .breadcrumb {
      margin-left: 30%;
      margin-bottom: 20px;
    }
    .title-box {
      // text-align: -webkit-center;
      margin-left: 50%;
      .title {
        width: 100px;
        font-size: 16px;
        font-weight: bold;
        padding-left: 10px;
        border-left: 2px solid #7f0114;
      }
      .name {
        width: 100px;
        font-size: 10px;
        padding-left: 10px;
        color: rgb(96, 94, 94);
      }
    }
    .tab-box {
      margin-top: 20px;
      .active {
        background: #7f0114;
        color: #fff;
        z-index: 99;
      }
      ul li {
        width: 160px;
        height: 35px;
        line-height: 35px;
        margin-left: 50%;
        padding-left: 10px;
        cursor: pointer;
      }
    }
  }
  .center {
    padding: 0 90px;
    width: 82%;
    margin-left:9%;
    background-image: url("../../assets/serve/bh33333.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    dt {
      font-size: 16px;
      font-weight: bold;
    }
    .agent-form {
      display: flex;
      .agent-box {
        width: 100%;
      }
      img {
        width: 60%;
        height: 400px;
      }
    }
    .title-img {
      margin: auto;
      margin-bottom: 20px;
      margin-top: 30px;
      width: 220px;
      height: 70px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .description {
      margin-bottom: 20px;
    }
    .img {
      width: 100%;
    }

    .qualification-box {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      align-items: center;
      .qualification {
        width: 200px;
        .img {
          width: 100%;
        }
        .qname {
          width: 100%;
          text-align: center;
          padding: 0 10px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .about-bottom {
      font-size: 16px;
      i {
        font-size: 25px;
      }
    }
  }
}
.honor {
  width: 100%;
}
.honor {
  padding-bottom: 40px;
  padding-top: 50px;
  .swiper-wrapper {
    width: 81%;
  }
  .swioer-item {
    width: 260px !important;
  }
}
.tab-box1 {
  display: none;
}
@media screen and (max-width: 750px) {
  .about-cen {
    padding: 0;
    .center {
      padding: 0 15px;
      width: 100%;
    }
  }
  .agent-form {
    img {
      display: none;
    }
  }
  .tab {
    display: none;
  }
  .qualification-box {
    img {
      width: 48% !important;
    }
    .qualification {
      width: 50% !important;
    }
  }
  .tab-box1 {
    display: block;
    padding-top: 10px;
    ul {
      display: flex;
      justify-content: space-around;
      li {
        font-size: 16px;
      }
      .active {
        color: #7f0114;
        border-bottom: 1px solid #7f0114;
      }
    }
  }
}
.swiper-box {
  position: relative;
  .btn-box {
    font-size: 20px;
    margin-top: 10px;
    span {
      color: rgb(95, 139, 200);
    }
  }
  .center0 {
    width: 100%;
    position: absolute;
    top: 240px;
    left: 30px;
    text-align: center;
    .name {
      font-size: 60px;
      color: #fff;
    }
    .title {
      font-size: 25px;
      color: #fff;
    }
    .description {
      font-size: 10px;
      color: #fff;
    }
  }
  .center1 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 80px;
    text-align: center;
    color: #000;
    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center2 {
    width: 40%;
    position: absolute;
    top: 35%;
    right: 190px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;
    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
}
</style>
